import React from 'react';
import Bugsnag from '@bugsnag/js';
import {
    BasicResponsiveImage,
    Box,
    Button,
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Typography,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { useAlertContext } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { useMatchMutation } from '../../../lib';

// There is a css issue because this modal is displayed on top of another modal and is inheriting its styles
// remove background and box shadow on modal footer
// & adds weight to the selector to make sure it's applied after the base styles.
// 5 is the required weight to apply the styles
const StyledFooter = styled(ModalDialogFooter)`
    @media (min-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        &&&&&::after {
            box-shadow: none;
        }
    }
`;

const ButtonsFullWidthContainer = styled(FlexBox)`
    flex: 1 0 0;
`;

interface ConfirmationModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    expertId: string;
    jobId: string;
    briefId: string;
    avatarUrl: string;
    displayName: string;
}
export default function ConfirmationModal({
    isOpen,
    setIsOpen,
    jobId,
    briefId,
    expertId,
    avatarUrl,
    displayName,
}: ConfirmationModalProps) {
    const screenClass = useScreenClass();
    const { showError } = useAlertContext();

    const [matchMutation, { loading }] = useMatchMutation({
        onCompleted: (data) => {
            window.location.href = data.match.next;
        },
        onError: (e) => {
            showError(__('Sorry, there was an error confirming this designer. Please try again'));
            Bugsnag.notify(e);
            setIsOpen(false);
        },
    });

    const handleConfirm = () => {
        matchMutation({
            variables: {
                jobId,
                briefId,
                expertId,
            },
        });
    };
    return (
        <ModalDialog
            variant={screenClass === 'xs' ? 'menu' : 'standard'}
            isOpen={isOpen}
            onRequestDismiss={() => setIsOpen(false)}
            bodyWidth="capped"
        >
            <ModalDialogContent aria-labelledby="modal dialog button">
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={__('Close')} />
                </ModalDialogNav>
                <FlexBox flexDirection="column" alignItems="center" gap={3}>
                    <Box style={{ width: '64px' }}>
                        <BasicResponsiveImage
                            key={`avatar-${expertId}`}
                            src={avatarUrl}
                            aspectRatio={1}
                            style={{ borderRadius: '50%' }}
                        />
                    </Box>
                    <Typography noWrap fontSkin="body-standard-bold">
                        {displayName}
                    </Typography>
                </FlexBox>
                <FlexBox style={{ flex: 1 }} flexDirection="column" textAlign="center">
                    <ModalDialogHeader
                        display="flex"
                        style={{ justifyContent: 'center' }}
                        marginTop="7"
                    >
                        <ModalDialogTitle>{__('Confirm your designer')}</ModalDialogTitle>
                    </ModalDialogHeader>
                    <ModalDialogBody>
                        {__(
                            'You’re about to confirm {{displayName}} as your selected designer. This will start your project with them.',
                            { displayName }
                        )}
                    </ModalDialogBody>
                    <StyledFooter backgroundColor={'standard'} pinned={screenClass === 'xs'}>
                        <ModalDialogButtons>
                            <ButtonsFullWidthContainer gap={3}>
                                <Button
                                    skin="secondary"
                                    width="full-width"
                                    standardMode
                                    onClick={() => setIsOpen(false)}
                                >
                                    {__('Cancel')}
                                </Button>
                                <Button
                                    skin="primary"
                                    width="full-width"
                                    standardMode
                                    onClick={handleConfirm}
                                    disabled={loading}
                                >
                                    {loading ? __('Confirming...') : __('Confirm')}
                                </Button>
                            </ButtonsFullWidthContainer>
                        </ModalDialogButtons>
                    </StyledFooter>
                </FlexBox>
            </ModalDialogContent>
        </ModalDialog>
    );
}
