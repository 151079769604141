import { useEffect } from 'react';

export function useSetXOverscrollingBlocked(isBlocked: boolean) {
    useEffect(() => {
        if (document) {
            document.body.style.overscrollBehaviorX = isBlocked ? 'none' : 'auto';
        }

        return () => {
            if (document) {
                document.body.style.overscrollBehaviorX = 'auto';
            }
        };
    });
}
