import React, { useState } from 'react';
import {
    BasicResponsiveImage,
    Box,
    Button,
    Carousel,
    CarouselSlide,
    Column,
    FluidImage,
    GridContainer,
    Icon,
    Link,
    RatingsStars,
    Row,
    SquareImageContainer,
    StandardTile,
    StandardTileContents,
    StandardTileImage,
    Typography,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { ApplicantsFragment, Size, useAttribution, useAvatar } from '../../lib';
import { usePitch } from '../../lib/hooks/usePitch';

const TruncatedText = styled(Typography)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    height: 3em;
`;

const AvatarWrapper = styled(Box)`
    min-width: 48px;
`;

const ImageOverlay = styled.div`
    background: var(
        --gradient-image-overlay,
        linear-gradient(
            180deg,
            #faf9f7 0%,
            rgba(250, 249, 247, 0) 20.83%,
            rgba(250, 249, 247, 0) 45.83%,
            rgba(250, 249, 247, 0) 69.27%,
            #faf9f7 100%
        )
    );
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
`;

interface ApplicantProps {
    applicant: ApplicantsFragment;
    onView: () => void;
}

export default function Applicant({ applicant, onView }: ApplicantProps) {
    const { expert, featuredDesigns } = applicant;
    const [showArrows, setShowArrows] = useState(false);
    const attribution = useAttribution(expert.displayName, featuredDesigns) as string[];
    const screenClass = useScreenClass();
    const avatarUrl = useAvatar(expert.id, Size.M);
    const pitch = usePitch(applicant);

    return (
        <StandardTile layout="vertical">
            <StandardTileImage
                onMouseEnter={() => setShowArrows(true)}
                onMouseLeave={() => setShowArrows(false)}
            >
                <Carousel
                    arrows={screenClass === 'xs' || showArrows}
                    progressIndicator="dots-inset"
                    accessibleTextForDots={attribution}
                    accessibleTextForPrevious={__('Go to previous slide')}
                    accessibleTextForNext={__('Go to next slide')}
                    slidesToShow={1}
                    key={expert.id}
                    skin="full"
                >
                    {featuredDesigns.length > 0 ? (
                        featuredDesigns.map((design) => (
                            <CarouselSlide key={design.id}>
                                <SquareImageContainer>
                                    <ImageOverlay />
                                    <FluidImage
                                        src={design.imageSources.mediaCrop}
                                        alt={design.title || attribution[0]}
                                    />
                                </SquareImageContainer>
                            </CarouselSlide>
                        ))
                    ) : (
                        <SquareImageContainer />
                    )}
                </Carousel>
            </StandardTileImage>
            <StandardTileContents>
                <GridContainer>
                    <Link
                        width="full-width"
                        skin="unstyled"
                        render={(p) => (
                            <Button skin="link" className={p.className} onClick={onView}>
                                {p.children}
                            </Button>
                        )}
                    >
                        <Row>
                            <Column span={12} display="flex">
                                <AvatarWrapper>
                                    <BasicResponsiveImage
                                        src={avatarUrl}
                                        aspectRatio={1}
                                        style={{ borderRadius: '50%' }}
                                    />
                                </AvatarWrapper>
                                <Box ml="3">
                                    <Row>
                                        <Column span={12} mb="2">
                                            <Typography fontSkin="title-item">
                                                {expert.displayName}
                                            </Typography>
                                        </Column>
                                        <Column span={12}>
                                            <RatingsStars
                                                skin="standard"
                                                ratingValue={expert.reviewRating}
                                            />
                                        </Column>
                                    </Row>
                                </Box>
                            </Column>
                        </Row>
                        <Row>
                            <Column span={12}>
                                <Box paddingY={4}>
                                    <TruncatedText textColor="subtle" fontSkin="body-small">
                                        {pitch}
                                    </TruncatedText>
                                </Box>
                            </Column>
                            <Column span={12}>
                                <Typography fontWeight="bold">
                                    {__('View designer')} <Icon iconType="arrowRight" />
                                </Typography>
                            </Column>
                        </Row>
                    </Link>
                </GridContainer>
            </StandardTileContents>
        </StandardTile>
    );
}
