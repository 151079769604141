import React from 'react';
import {
    Box,
    BulletList,
    BulletListContent,
    BulletListIcon,
    Button,
    Icon,
    Li,
    Link,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Typography,
    getSwanIconUrl,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import { Product, ProductPrice } from '../ProductCard/ProductCard';

interface ProductModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    product: Product;
}

export function ProductModal({ isOpen, setIsOpen, product }: ProductModalProps) {
    const hasAdditionalDetails =
        product.additionalDetails?.length && product.additionalDetails.length > 0;

    return (
        <>
            <Link
                mt={'5'}
                render={(p) => (
                    <Button
                        skin="link"
                        className={p.className}
                        iconPosition="left"
                        onClick={() => setIsOpen(true)}
                    >
                        <Icon iconType="info" size="16p" />
                        {p.children}
                    </Button>
                )}
            >
                <span>{__('What’s included and extra costs')}</span>
            </Link>
            <ModalDialog
                variant="panel-right"
                isOpen={isOpen}
                onRequestDismiss={() => {
                    setIsOpen(false);
                }}
            >
                <ModalDialogContent aria-labelledby="modal dialog panel">
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText={__('Close')} />
                    </ModalDialogNav>
                    <ModalDialogHeader>
                        <ModalDialogTitle>{product.title}</ModalDialogTitle>
                        <ProductPrice
                            amountInCents={product.pricing.amount.amountInCents}
                            currency={product.pricing.amount.currency}
                            showFixedPrice
                        />
                    </ModalDialogHeader>
                    <ModalDialogBody pb={8}>
                        {product.description && (
                            <Box mb="7">
                                <Typography>{product.description}</Typography>
                            </Box>
                        )}
                        {product.details.length > 0 && (
                            <Typography fontWeight="bold" mb="5">
                                {__('What’s included:')}
                            </Typography>
                        )}
                        <BulletList mb="6">
                            {product.details.map((item) => (
                                <Li key={item.title}>
                                    <BulletListIcon
                                        src={getSwanIconUrl('check') ?? ''}
                                        width={20}
                                        height={20}
                                        alt={__('check icon')}
                                    />
                                    <BulletListContent>
                                        <Typography fontSize="small" fontWeight="bold" mb="2">
                                            {item.title}
                                        </Typography>
                                        <Typography fontSize="small">{item.description}</Typography>
                                    </BulletListContent>
                                </Li>
                            ))}
                        </BulletList>
                        {hasAdditionalDetails && (
                            <Typography fontWeight="bold" mb="5">
                                {__('Things that might cost more outside of this package:')}
                            </Typography>
                        )}
                        <BulletList>
                            {product.additionalDetails?.map((item) => (
                                <Li key={item.title}>
                                    <BulletListIcon
                                        src={getSwanIconUrl('check') ?? ''}
                                        width={20}
                                        height={20}
                                        alt={__('check icon')}
                                    />
                                    <BulletListContent>
                                        <Typography fontSize="small" fontWeight="bold" mb="2">
                                            {item.title}
                                        </Typography>
                                        <Typography fontSize="small">{item.description}</Typography>
                                    </BulletListContent>
                                </Li>
                            ))}
                        </BulletList>
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
}
