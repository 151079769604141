/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import { ApplicationFragment } from '../fragments/Application.generated';
import { ExpertDesignFragment, ExpertFragment } from '../fragments/Expert.generated';

export type ApplicantsQueryVariables = Types.Exact<{
    jobId: Types.Scalars['String'];
    state?: Types.Maybe<Types.ApplicationState>;
    sort?: Types.Maybe<Types.ApplicationSort>;
}>;

export type ApplicantsQuery = {
    __typename: 'Query';
    applicants: Types.Maybe<
        Array<{
            __typename: 'Applicant';
            expert: { __typename: 'CreatorHit' } & ExpertFragment;
            application: { __typename: 'Application' } & ApplicationFragment;
            featuredDesigns: Array<{ __typename: 'CreatorDesign' } & ExpertDesignFragment>;
        }>
    >;
};

export const ApplicantsDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Applicants' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationState' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationSort' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicants' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'state' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sort' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expert' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Expert' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'application' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Application' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'featuredDesigns' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ExpertDesign' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Expert' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatorHit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'certifications' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'completedProjectsCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'repeatCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'responsivenessScore' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reviewRating' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastSeenAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isOnline' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'utcOffset' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'categories' },
                        name: { kind: 'Name', value: 'categoriesWithValues' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locales' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Application' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Application' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pitch' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'portfolio' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'order' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'sortPosition' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ExpertDesign' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatorDesign' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSources' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mediaCrop' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'original' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useApplicantsQuery__
 *
 * To run a query within a React component, call `useApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      state: // value for 'state'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useApplicantsQuery(
    baseOptions: Apollo.QueryHookOptions<ApplicantsQuery, ApplicantsQueryVariables>
) {
    return Apollo.useQuery<ApplicantsQuery, ApplicantsQueryVariables>(
        ApplicantsDocument,
        baseOptions
    );
}
export function useApplicantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApplicantsQuery, ApplicantsQueryVariables>
) {
    return Apollo.useLazyQuery<ApplicantsQuery, ApplicantsQueryVariables>(
        ApplicantsDocument,
        baseOptions
    );
}
export type ApplicantsQueryHookResult = ReturnType<typeof useApplicantsQuery>;
export type ApplicantsLazyQueryHookResult = ReturnType<typeof useApplicantsLazyQuery>;
export type ApplicantsQueryResult = Apollo.QueryResult<ApplicantsQuery, ApplicantsQueryVariables>;
