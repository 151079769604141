import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Layout, OpportunityProvider } from '@99designs/matching';

type MatchingParams = {
    jobId: string;
};

export function Matching() {
    const { jobId } = useParams<MatchingParams>() as MatchingParams;
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const sub = `/${jobId}`;
        if (path === sub || path === `${sub}/`) {
            navigate(`${sub}/overview`);
        }
    }, [jobId, navigate, path]);

    return (
        <OpportunityProvider id={jobId}>
            <Layout>
                <Outlet />
            </Layout>
        </OpportunityProvider>
    );
}
