import {
    Box,
    Column,
    FlexBox,
    FluidImage,
    GridContainer,
    Row,
    SquareImageContainer,
    Typography,
    responsive,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import jobStep1 from '../../assets/images/job-step-1.png';
import jobStep2 from '../../assets/images/job-step-2.png';
import jobStep3 from '../../assets/images/job-step-3.png';
import { GettingMatchedLink } from '../../lib';

interface StepCardProps {
    image: string;
    number: number;
    title: string;
    description: string;
}

export function InactiveApplications() {
    const cardSteps = [
        {
            image: jobStep1,
            number: 1,
            title: __('Designers pitch for your project'),
            description: __(
                'Designers whose skills and preferences are a good fit for your project will now review your brief and let you know they are interested.'
            ),
        },
        {
            image: jobStep2,
            number: 2,
            title: __('Select your designer'),
            description: __(
                'As designers express interest in your project, they will appear here. You can view their portfolios, profiles, and reviews to help choose a designer to work with.'
            ),
        },
        {
            image: jobStep3,
            number: 3,
            title: __('Your project kicks off'),
            description: __(
                "Once you've selected a designer, the project will officially launch and they will message you with any questions or updates."
            ),
        },
    ];

    return (
        <FlexBox flexDirection="column" alignItems="center" my={8}>
            <Box>
                <Box mx={3} mb={7}>
                    <Typography fontSkin="title-headline" mb={2}>
                        {__('What happens next...')}
                    </Typography>
                    <Typography fontSkin="body-standard">
                        {__('Designers will soon express interest in your project here')}
                    </Typography>
                </Box>
                <GridContainer mb={8}>
                    <Row>
                        {cardSteps.map((step) => (
                            <StepCard key={`${step.number}-card-step`} {...step} />
                        ))}
                    </Row>
                </GridContainer>
                <Box textAlign="center">
                    <Typography fontSkin="body-small" mb={2}>
                        {__('Unsure how to find the right designer for your project?')}
                    </Typography>
                    <GettingMatchedLink skin="standard">
                        <Typography fontSkin="body-small-bold">
                            {__('Check out our help guide')}
                        </Typography>
                    </GettingMatchedLink>
                </Box>
            </Box>
        </FlexBox>
    );
}

const ResponsiveStepDescription = responsive(Typography);

function StepCard({ image, number, title, description }: StepCardProps) {
    return (
        <Column span={4} px={6}>
            <SquareImageContainer style={{ height: '173px' }} mb={6}>
                <FluidImage src={image} />
            </SquareImageContainer>
            <FlexBox>
                <Typography fontSkin="title-headline" mr={4}>
                    {number.toString().padStart(2, '0')}
                </Typography>
                <Box>
                    <Typography fontSkin="title-item" mb={2}>
                        {title}
                    </Typography>
                    <ResponsiveStepDescription
                        xs={{
                            fontSize: 'small',
                        }}
                        md={{
                            style: {
                                fontSize: '16px',
                                width: '230px',
                            },
                        }}
                    >
                        {description}
                    </ResponsiveStepDescription>
                </Box>
            </FlexBox>
        </Column>
    );
}
