import { ReactNode } from 'react';
import { Icon, Link } from '@vp/swan';

export function GettingMatchedLink({
    skin,
    children,
}: {
    skin: 'standard' | 'unstyled';
    children: ReactNode;
}) {
    return (
        <Link
            to="https://support.99designs.com/hc/en-us/articles/8934876076692-Getting-matched-with-a-designer"
            target="_blank"
            rel="noopener"
            withIcon
            skin={skin}
        >
            {children}
            <Icon iconType="designEducation" size="16p" ml={2} />
        </Link>
    );
}
