/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type ProductImageUrlQueryVariables = Types.Exact<{
    mpvID: Types.Scalars['String'];
}>;

export type ProductImageUrlQuery = { __typename: 'Query'; productImageUrl: string };

export const ProductImageUrlDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ProductImageUrl' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mpvID' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productImageUrl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mpvID' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'mpvID' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
};

/**
 * __useProductImageUrlQuery__
 *
 * To run a query within a React component, call `useProductImageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductImageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductImageUrlQuery({
 *   variables: {
 *      mpvID: // value for 'mpvID'
 *   },
 * });
 */
export function useProductImageUrlQuery(
    baseOptions: Apollo.QueryHookOptions<ProductImageUrlQuery, ProductImageUrlQueryVariables>
) {
    return Apollo.useQuery<ProductImageUrlQuery, ProductImageUrlQueryVariables>(
        ProductImageUrlDocument,
        baseOptions
    );
}
export function useProductImageUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ProductImageUrlQuery, ProductImageUrlQueryVariables>
) {
    return Apollo.useLazyQuery<ProductImageUrlQuery, ProductImageUrlQueryVariables>(
        ProductImageUrlDocument,
        baseOptions
    );
}
export type ProductImageUrlQueryHookResult = ReturnType<typeof useProductImageUrlQuery>;
export type ProductImageUrlLazyQueryHookResult = ReturnType<typeof useProductImageUrlLazyQuery>;
export type ProductImageUrlQueryResult = Apollo.QueryResult<
    ProductImageUrlQuery,
    ProductImageUrlQueryVariables
>;
