import React, { PropsWithChildren } from 'react';
import { Box, tokensRaw, useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import { BottomBar } from '@99designs/design-services-layouts';
import { Sidebar, Topbar } from '@99designs/design-services-layouts';
import { useHeader } from '../hooks';

const FullScreenLayout = styled.div`
    display: flex;
    height: 100dvh;
    width: 100vw;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 0;
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    overflow: auto;
`;

export function Layout({ children }: PropsWithChildren) {
    const { menu, links } = useHeader();
    const screenClass = useScreenClass();

    return (
        <FullScreenLayout>
            {screenClass !== 'xs' && (
                <Box compactMode>
                    <Sidebar links={links} />
                </Box>
            )}
            <ContentWrapper>
                <Topbar {...menu} />
                <Content>{children}</Content>
            </ContentWrapper>
            {screenClass === 'xs' && (
                <BottomBar bottomBarLinks={links} zIndex={tokensRaw.SwanSemZIndexOverlay} />
            )}
        </FullScreenLayout>
    );
}
