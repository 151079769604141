import React, { useState } from 'react';
import {
    BasicResponsiveImage,
    Box,
    Button,
    Checkbox,
    Divider,
    FlexBox,
    Icon,
    Link,
    RatingsStars,
    Typography,
    Visible,
} from '@vp/swan';
import { formatDistance } from 'date-fns';
import { __ } from '@99designs/i18n';
import { ExpertFragment, Size, useAvatar } from '../../../lib';
import ConfirmationModal from './ConfirmationModal';

export interface ApplicantSummaryProps {
    expert: ExpertFragment;
    jobId: string;
    pitch?: string;
    isConfirmationModalOpen: boolean;
    setIsConfirmationModalOpen: (isOpen: boolean) => void;
}

export default function ApplicantSummary({
    expert,
    jobId,
    pitch,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
}: ApplicantSummaryProps) {
    // TODO: Implement picking designer

    const applicantAvatarUrl = useAvatar(expert.id, Size.S);
    const pitchOrBio: string = pitch || expert.biography;

    return (
        <Box>
            <FlexBox alignItems="center" mb={4}>
                <Box mr={3} style={{ width: '64px' }}>
                    <BasicResponsiveImage
                        key={`avatar-${expert.id}`}
                        src={applicantAvatarUrl}
                        aspectRatio={1}
                        style={{ borderRadius: '50%' }}
                    />
                </Box>
                <Box>
                    <Typography fontSkin="title-subsection">{expert.displayName}</Typography>
                    <OnlineStatus isOnline={expert.isOnline} lastSeenAt={expert.lastSeenAt} />
                </Box>
                <Box style={{ marginLeft: 'auto' }} hidden>
                    {/* // TODO: Implement designer shortlisting */}
                    <Checkbox skin="favorite" />
                </Box>
            </FlexBox>
            <Box mb={6}>
                <Typography mb={4} style={{ whiteSpace: 'pre-wrap' }}>
                    {pitchOrBio}
                </Typography>
                <FlexBox alignItems="center">
                    <RatingsStars ratingValue={expert.reviewRating} mr={3} />
                    <Link
                        to={`https://99designs.com/profiles/${expert.id}/about?jobId=${jobId}`}
                        target="_blank"
                        rel="noopener"
                    >
                        <Typography fontSkin="body-small">
                            {__('{{reviewCount}} Reviews', { reviewCount: expert.reviewCount })}
                        </Typography>
                    </Link>
                </FlexBox>
            </Box>
            <Visible md lg xl>
                <Box mb={6}>
                    <ExpertCTAs
                        jobId={jobId}
                        expert={expert}
                        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                    />
                    <Divider mx={6} mt={5} />
                </Box>
            </Visible>
            <Box>
                <ConfirmationModal
                    briefId={jobId}
                    jobId={jobId}
                    isOpen={isConfirmationModalOpen}
                    setIsOpen={setIsConfirmationModalOpen}
                    expertId={expert.id}
                    avatarUrl={applicantAvatarUrl}
                    displayName={expert.displayName}
                />
            </Box>
        </Box>
    );
}

interface OnlineStatusProps {
    isOnline: boolean;
    lastSeenAt: string | Date;
}

function OnlineStatus({ isOnline, lastSeenAt }: OnlineStatusProps) {
    // Note that test data will eventually show "1 year ago" etc. if not updated.

    if (isOnline) {
        return (
            <FlexBox alignItems="center">
                <Box
                    mr={2}
                    backgroundColor="black"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                    }}
                />
                <Typography fontSkin="body-small">{__('Online now')}</Typography>
            </FlexBox>
        );
    }

    return (
        <Typography fontSkin="body-small">
            {__('Online {{lastSeenAt}}', {
                lastSeenAt: formatDistance(new Date(lastSeenAt), new Date(), { addSuffix: true }),
            })}
        </Typography>
    );
}

interface ExpertCTAsProps {
    jobId: string;
    expert: ExpertFragment;
    setIsConfirmationModalOpen: (isOpen: boolean) => void;
}

export function ExpertCTAs({ jobId, expert, setIsConfirmationModalOpen }: ExpertCTAsProps) {
    return (
        <>
            <Button
                skin="primary"
                specialVariant="design-path"
                width="full-width"
                iconPosition="right"
                onClick={() => setIsConfirmationModalOpen(true)}
            >
                <Typography>
                    {__('Select {{designer}}', { designer: expert.displayName })}
                </Typography>
                <Typography fontSkin="body-small">{__('Start your project now')}</Typography>
                <Icon iconType="check" />
            </Button>
            <FlexBox justifyContent="center">
                <Link
                    to={`https://99designs.com/profiles/${expert.id}?jobId=${jobId}`}
                    target="_blank"
                    rel="noopener"
                    skin="cta"
                    mt={5}
                >
                    {__('View full profile on 99designs by Vista')}
                </Link>
            </FlexBox>
        </>
    );
}
