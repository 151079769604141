import { __ } from '@99designs/i18n';
import { ExpertDesignFragment } from '../graphql';

export function useAttribution(expert: string, designs: ExpertDesignFragment[]): string | string[] {
    if (Array.isArray(designs)) {
        return designs.map(() => {
            return __('Design by expert: {expertName}', {
                expertName: expert,
            });
        });
    }

    return __('Design by expert: {expertName}', { expertName: expert });
}
