import React, { createContext, useContext } from 'react';
import { getApolloErrorType } from '@99designs/design-services-common';
import * as Types from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { ErrorPage, FullPageSpinner, PermissionErrorPage } from '../../components';
import { BriefFragment } from '../../graphql';
import { JobFragment } from '../../graphql';
import { ProductFragment } from '../../graphql';
import { useOpportunityQuery } from '../../graphql';
import { ApplicantsFragment } from '../../graphql';

interface OpportunityContextProps {
    id: string;
    children?: React.ReactNode;
}

type OpportunityContextData = {
    id: string;
    brief: BriefFragment;
    product: ProductFragment;
    job: Types.Maybe<{ __typename: 'Job' } & JobFragment>;
    applicants: Types.Maybe<Array<{ __typename: 'Applicant' } & ApplicantsFragment>>;
};

export const OpportunityContext = createContext<OpportunityContextData | null>(null);

export const OpportunityProvider = ({ id, children }: OpportunityContextProps) => {
    const { data, loading, error } = useOpportunityQuery({
        variables: {
            id: id,
        },
    });

    if (loading) {
        return <FullPageSpinner />;
    }

    if (error) {
        switch (getApolloErrorType(error)) {
            case 'PERMISSION_DENIED':
                return <PermissionErrorPage />;
            default:
                return <ErrorPage error={error} />;
        }
    }

    if (!data) {
        return <ErrorPage error={new Error('no data returned from opportunity query')} />;
    }

    const { job, applicants, brief, product } = data.opportunity;

    return (
        <OpportunityContext.Provider
            value={{
                id: id,
                job: job,
                brief: brief,
                product: product,
                applicants: applicants,
            }}
        >
            {children}
        </OpportunityContext.Provider>
    );
};

export const useOpportunityContext = () => {
    const context = useContext(OpportunityContext);
    if (!context) {
        throw new Error('useOpportunityContext must be used within a OpportunityProvider');
    }
    return context;
};

export const useJob = (): JobFragment => {
    const { job } = useOpportunityContext();

    if (!job) {
        throw new Error(
            'useJob may only be called after the job has been created and set in context'
        );
    }

    return job;
};
