/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import { ApplicantsFragment } from '../fragments/Applicants.generated';
import { BriefFragment } from '../fragments/Brief.generated';
import { JobFragment } from '../fragments/Job.generated';
import { ProductFragment } from '../fragments/Product.generated';

export type OpportunityQueryVariables = Types.Exact<{
    id: Types.Scalars['String'];
}>;

export type OpportunityQuery = {
    __typename: 'Query';
    opportunity: {
        __typename: 'Opportunity';
        job: Types.Maybe<{ __typename: 'Job' } & JobFragment>;
        applicants: Types.Maybe<Array<{ __typename: 'Applicant' } & ApplicantsFragment>>;
        brief: { __typename: 'StructuredBrief' } & BriefFragment;
        product: { __typename: 'ProductFixedPriceProject' } & ProductFragment;
    };
};

export const OpportunityDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Opportunity' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'opportunity' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'job' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Job' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicants' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Applicants' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'brief' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Brief' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Product' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Job' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Job' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permissions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canApplyWithExperience' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canLaunchAsProject' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canInteractWithJob' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'canMatch' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'canList' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flags' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasEmbeddings' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Applicants' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Applicant' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expert' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Expert' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'application' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Application' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredDesigns' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ExpertDesign' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Expert' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatorHit' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'certifications' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'completedProjectsCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'repeatCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'responsivenessScore' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reviewRating' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastSeenAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isOnline' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'utcOffset' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'categories' },
                        name: { kind: 'Name', value: 'categoriesWithValues' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locales' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Application' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Application' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pitch' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'portfolio' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'order' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'sortPosition' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ExpertDesign' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatorDesign' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSources' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mediaCrop' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'original' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Brief' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dynamicFields' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Field' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Field' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiInputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextAreaField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SelectChoiceField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RadioChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilesField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiGridChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientConfiguredField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkEntityField' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'helperText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'inputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiInputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'multiInputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextAreaField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValues' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RadioChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'view' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BudgetField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DateField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FilesField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedFileTypes' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filesValue' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bucket' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'downloadUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiGridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiGridChoiceValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxSelections' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'validationPrompt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ClientConfiguredField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientConfiguredValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WorkEntityField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Product' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ProductFixedPriceProject' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'src' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerVideo' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'src' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricing' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'earningsInUsd' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amountInCents' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useOpportunityQuery__
 *
 * To run a query within a React component, call `useOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityQuery(
    baseOptions: Apollo.QueryHookOptions<OpportunityQuery, OpportunityQueryVariables>
) {
    return Apollo.useQuery<OpportunityQuery, OpportunityQueryVariables>(
        OpportunityDocument,
        baseOptions
    );
}
export function useOpportunityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OpportunityQuery, OpportunityQueryVariables>
) {
    return Apollo.useLazyQuery<OpportunityQuery, OpportunityQueryVariables>(
        OpportunityDocument,
        baseOptions
    );
}
export type OpportunityQueryHookResult = ReturnType<typeof useOpportunityQuery>;
export type OpportunityLazyQueryHookResult = ReturnType<typeof useOpportunityLazyQuery>;
export type OpportunityQueryResult = Apollo.QueryResult<
    OpportunityQuery,
    OpportunityQueryVariables
>;
