import React from 'react';
import {
    Box,
    Callout,
    Column,
    FlexBox,
    GridContainer,
    Icon,
    Row,
    Typography,
    responsive,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import { ExpertFragment } from '../../../lib';

const ResponsiveTypography = responsive(Typography);

interface ApplicantDetailsProps {
    expert: ExpertFragment;
}

export default function ApplicantDetails({ expert }: ApplicantDetailsProps) {
    const languageDisplay = expert.locales.map((locale) => locale.title).join(', ');
    const certificationDisplay = expert.certifications.map(capitalizeFirstLetter).join(', ');
    const categoryDisplay = expert.categories.map((category) => (
        <CategoryItem key={category.key} title={category.title} />
    ));
    return (
        <Box>
            <Box mb={5}>
                <Typography fontSkin="body-standard-bold" mb={2}>
                    {__('Experience')}
                </Typography>
                <GridContainer>
                    <Row>
                        <ExperienceItem
                            label={__('1-to-1 Projects')}
                            value={`${expert.completedProjectsCount}:`}
                        />
                        <ExperienceItem
                            label={__('Repeat Clients')}
                            value={`${expert.repeatCount}:`}
                        />
                        <ExperienceItem
                            label={__('Responses in 24hr')}
                            value={`${expert.responsivenessScore}%`}
                            isFullWidth
                            isLastItem
                        />
                    </Row>
                </GridContainer>
            </Box>
            <Box mb={5}>
                <Typography fontSkin="body-standard-bold" mb={2}>
                    {__('Languages')}
                </Typography>
                <Typography>{languageDisplay}</Typography>
            </Box>
            <Box mb={5}>
                <Typography fontSkin="body-standard-bold" mb={2}>
                    {__('Certifications')}
                </Typography>
                <Typography>{certificationDisplay}</Typography>
            </Box>
            <Box>
                <Typography fontSkin="body-standard-bold" mb={2}>
                    {__('Categories')}
                </Typography>
                <Box style={{ margin: '0 -4px' }}>{categoryDisplay}</Box>
            </Box>
        </Box>
    );
}

interface ExperienceItemProps {
    value: string;
    label: string;

    isFullWidth?: boolean;
    isLastItem?: boolean;
}

function ExperienceItem(props: ExperienceItemProps) {
    return (
        <Column span={props.isFullWidth ? 12 : 6} mb={props.isLastItem ? '0' : '4'}>
            <FlexBox alignItems="center">
                <Icon iconType="satisfaction" size="16p" mr={{ xs: 2, md: 3 }} my={2} />
                <ResponsiveTypography
                    xs={{ fontSkin: 'body-small-bold' }}
                    md={{ fontSkin: 'body-standard-bold' }}
                    mr={2}
                >
                    {props.value}
                </ResponsiveTypography>
                <ResponsiveTypography
                    xs={{ fontSkin: 'body-small' }}
                    md={{ fontSkin: 'body-standard' }}
                    overflow="visible"
                >
                    {props.label}
                </ResponsiveTypography>
            </FlexBox>
        </Column>
    );
}

interface CategoryItemProps {
    title: string;
}

function CategoryItem({ title }: CategoryItemProps) {
    return (
        <Callout m={2} emphasis="low">
            {title}
        </Callout>
    );
}

function capitalizeFirstLetter(string: string) {
    return string.slice(0, 1).toLocaleUpperCase() + string.slice(1);
}
