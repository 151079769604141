import React, { ReactNode } from 'react';
import { Column } from '@vp/swan';

export function GridCard({ children }: { children: ReactNode }) {
    return (
        <Column span={12} spanSm={6} spanMd={4} spanLg={3} spanXl={3}>
            {children}
        </Column>
    );
}
