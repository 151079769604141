import React from 'react';
import { Box, Column, GridContainer, Row, Typography } from '@vp/swan';
import { FieldView, ProductCard } from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { BriefFragment, ProductFragment } from '../../lib';

interface BriefProps {
    brief: BriefFragment;
    product: ProductFragment;
}

export const Brief = ({ brief, product }: BriefProps) => {
    return (
        <GridContainer my={9}>
            <Row>
                <Column span={12}>
                    <Typography fontSkin="title-headline" px={5}>
                        {__('Your design project details')}
                    </Typography>
                </Column>
            </Row>
            <Row>
                <Column span={12} px={5}>
                    <Box my={7} px={5}>
                        <ProductCard product={product} withProductModal />
                    </Box>
                    {brief.dynamicFields.map((field) => (
                        <FieldView key={field.id} field={field} skin={'standard'} />
                    ))}
                </Column>
            </Row>
        </GridContainer>
    );
};
