/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type MatchMutationVariables = Types.Exact<{
    jobId: Types.Scalars['String'];
    briefId: Types.Scalars['String'];
    expertId: Types.Scalars['String'];
}>;

export type MatchMutation = {
    __typename: 'Mutation';
    match: { __typename: 'Match'; id: string; next: string };
};

export const MatchDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'Match' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'briefId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'expertId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'match' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'briefId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'briefId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'expertId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'expertId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type MatchMutationFn = Apollo.MutationFunction<MatchMutation, MatchMutationVariables>;

/**
 * __useMatchMutation__
 *
 * To run a mutation, you first call `useMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchMutation, { data, loading, error }] = useMatchMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      briefId: // value for 'briefId'
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useMatchMutation(
    baseOptions?: Apollo.MutationHookOptions<MatchMutation, MatchMutationVariables>
) {
    return Apollo.useMutation<MatchMutation, MatchMutationVariables>(MatchDocument, baseOptions);
}
export type MatchMutationHookResult = ReturnType<typeof useMatchMutation>;
export type MatchMutationResult = Apollo.MutationResult<MatchMutation>;
export type MatchMutationOptions = Apollo.BaseMutationOptions<
    MatchMutation,
    MatchMutationVariables
>;
