import React from 'react';
import {
    CarouselSlide,
    FluidImage,
    ThumbnailsHero,
    ThumbnailsHeroImagesSlidesContainer,
    ThumbnailsHeroThumbnailsSlidesContainer,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import { ExpertDesignFragment, ExpertFragment, useAttribution } from '../../../lib';

interface ApplicantDesignCarouselProps {
    designs: ExpertDesignFragment[];
    expert: ExpertFragment;
}

export default function ApplicantDesignCarousel({ designs, expert }: ApplicantDesignCarouselProps) {
    const accessibleTextForDots = useAttribution(expert.displayName, designs) as string[];

    return (
        <ThumbnailsHero
            key={`${expert.id}-thumbnail-hero`}
            accessibleTextForDots={accessibleTextForDots}
            m={3}
        >
            <ThumbnailsHeroThumbnailsSlidesContainer>
                {designs.map((item) => {
                    return (
                        <CarouselSlide key={`thumbnail-${item.id}`}>
                            <FluidImage
                                src={item.imageSources.mediaCrop}
                                alt={__('thumbnail for design {{design}}', {
                                    design: item.title || item.id,
                                })}
                            />
                        </CarouselSlide>
                    );
                })}
            </ThumbnailsHeroThumbnailsSlidesContainer>
            <ThumbnailsHeroImagesSlidesContainer>
                {designs.map((item) => {
                    return (
                        <CarouselSlide key={`full-size-${item.id}`}>
                            <FluidImage
                                src={item.imageSources.original}
                                alt={__('full-sized image for design {{design}}', {
                                    design: item.title || item.id,
                                })}
                                backgroundColor="info"
                                style={{ objectFit: 'contain' }}
                            />
                        </CarouselSlide>
                    );
                })}
            </ThumbnailsHeroImagesSlidesContainer>
        </ThumbnailsHero>
    );
}
