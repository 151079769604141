import { useTracking } from '@vp/react-tracking';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BoundedContent } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { InactiveApplications, Selection, useOpportunityContext } from '@99designs/matching';

export function Applicants() {
    const { tracking } = useTracking();

    const { job, applicants } = useOpportunityContext();
    const inactive = !job || !applicants || applicants.length === 0;

    useEffect(() => {
        // The equivalent event for 99designs is "Job designers viewed"

        tracking.page('Design Services:Matching:Applicants', {
            pageName: 'Design Services:Matching:Applicants',
            pageSection: 'Design Services',
            pageStage: 'Design',
        });
    }, [tracking]);

    return (
        <BoundedContent>
            <Helmet>
                <title>{__('Vistaprint | Designer selection')}</title>
            </Helmet>
            {inactive ? (
                <InactiveApplications />
            ) : (
                <Selection applicants={applicants} jobId={job.id} />
            )}
        </BoundedContent>
    );
}
