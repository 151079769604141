import React, { useState } from 'react';
import {
    Box,
    Column,
    FlexBox,
    GridContainer,
    H1,
    Hidden,
    PipeSeparator,
    Row,
    Typography,
} from '@vp/swan';
import styled from 'styled-components';
import { bugtracker } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import {
    ApplicantsFragment,
    GettingMatchedLink,
    GridCard,
    Skeleton,
    Sort,
    useApplicantsLazyQuery,
    useSetXOverscrollingBlocked,
} from '../../lib';
import Applicant from './Applicant';
import ApplicantInspector from './inspector';

const ApplicantRow = styled(Row)`
    width: 100%;
    max-width: 100%;

    overflow-x: hidden;
`;

interface SelectionProps {
    jobId: string;
    applicants: ApplicantsFragment[];
}

export function Selection({ applicants: initialApplicants, jobId }: SelectionProps) {
    const [isInspectorOpen, setIsInspectorOpen] = useState(false);
    const [inspectedApplicantIndex, setInspectedApplicantIndex] = useState<number | null>(null);
    const [activeApplicants, setSortedApplicants] =
        useState<ApplicantsFragment[]>(initialApplicants);

    const [getApplicants, { loading }] = useApplicantsLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSortedApplicants(data.applicants || []);
        },
        onError: (error) => {
            bugtracker.notify(error);
        },
    });

    useSetXOverscrollingBlocked(true);

    return (
        <GridContainer my={8}>
            <Row>
                <Column span={12}>
                    <Typography fontSkin="title-headline" mb={4}>
                        {__('Select your project designer')}
                    </Typography>
                    <Typography textColor="subtle" mb={5}>
                        <Box mb={{ xs: 2, sm: 2, md: 0 }} display={{ md: 'inline-block' }}>
                            {__(
                                'Review designer applications to help choose the best one to work with'
                            )}
                        </Box>
                        <Hidden xs sm as="span">
                            <PipeSeparator />
                        </Hidden>
                        <GettingMatchedLink skin="standard">
                            {__('How to pick the right designer')}
                        </GettingMatchedLink>
                    </Typography>
                </Column>
            </Row>
            <Row>
                <Column span={12}>
                    <FlexBox
                        flexDirection="row"
                        gap="5"
                        alignItems="center"
                        flexWrap="wrap"
                        my="to-actions"
                    >
                        <Sort query={getApplicants} />
                        <Typography textColor="subtle" fontSize="small" fontWeight="bold">
                            {`${activeApplicants.length} applicants`}
                        </Typography>
                    </FlexBox>
                </Column>
            </Row>
            {loading ? (
                <Row>
                    {activeApplicants.map((a) => (
                        <GridCard key={`skel-${a.application.id}`}>
                            <Skeleton />
                        </GridCard>
                    ))}
                </Row>
            ) : (
                <>
                    <ApplicantRow>
                        {activeApplicants.map((a, i) => (
                            <GridCard key={`card-${a.application.id}`}>
                                <Applicant
                                    applicant={a}
                                    onView={() => {
                                        setIsInspectorOpen(true);
                                        setInspectedApplicantIndex(i);
                                    }}
                                />
                            </GridCard>
                        ))}
                    </ApplicantRow>
                    <Row>
                        <Column span={12}>
                            <Box mb={8} mt={8} textAlign="center">
                                <Typography fontSkin="title-subsection">
                                    {__("That's all for now")}
                                </Typography>
                                <Typography fontSkin="body-standard">
                                    {__(
                                        "We'll let you know when designers register interest in your project."
                                    )}
                                </Typography>
                            </Box>
                        </Column>
                    </Row>
                </>
            )}
            <Row>
                <ApplicantInspector
                    jobId={jobId}
                    isOpen={inspectedApplicantIndex !== null && isInspectorOpen}
                    setIsOpen={setIsInspectorOpen}
                    applicants={activeApplicants}
                    inspectedApplicantIndex={inspectedApplicantIndex || 0}
                    setInspectedExpertIndex={setInspectedApplicantIndex}
                />
            </Row>
        </GridContainer>
    );
}
