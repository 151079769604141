import { ComponentProps } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { tokensRaw } from '@vp/swan';
import { MenuLinks, NavigationLink, Topbar } from '@99designs/design-services-layouts';
import { JobState } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { DESIGN_HUB, HELP_CENTRE, MY_ACCOUNT, MY_DESIGN_SERVICES } from '../constants';
import { useOpportunityContext } from '../contexts';
import { useTruncation } from './useTruncation';

const MAX_TITLE_LENGTH = 45;

interface Status {
    color: string;
    label: string;
}

export function useHeader() {
    const { id, brief, job } = useOpportunityContext();
    const location = useLocation();
    const navigate = useNavigate();

    const Statuses: Record<JobState, Status> = {
        JOB_STATE_OPEN: {
            label: __('Selection in progress'),
            color: tokensRaw.SwanBaseColorGreen700,
        },
        JOB_STATE_MATCHED: {
            label: __('Design in progress'),
            color: tokensRaw.SwanBaseColorBlue700,
        },
        JOB_STATE_CLOSED: {
            label: __('Canceled'),
            color: tokensRaw.SwanBaseColorRed700,
        },
    };

    const links: NavigationLink[] = [
        {
            icon: 'info',
            label: __('Overview'),
            onClick: () => {
                navigate(`/${id}/overview`);
            },
            isActive: location.pathname.includes('/overview'),
        },
        {
            icon: 'workWithAPro',
            label: __('Designer selection'),
            onClick: () => {
                navigate(`/${id}/applicants`);
            },
            isActive: location.pathname.includes('/applicants'),
        },
    ];

    const status = job ? Statuses[job.state] : Statuses['JOB_STATE_OPEN']; // Default "pending" status.

    const menuLinks = [
        {
            label: __('My Design Services'),
            path: MY_DESIGN_SERVICES,
            iconType: 'myProjects' as const,
        },
        {
            label: __('My Account'),
            path: MY_ACCOUNT,
            iconType: 'account' as const,
        },
        {
            label: __('Start a new project'),
            path: DESIGN_HUB,
            iconType: 'plus' as const,
        },
        {
            label: __('Help centre'),
            path: HELP_CENTRE,
            iconType: 'helpQuery' as const,
        },
    ];

    const menu: ComponentProps<typeof Topbar> = {
        status: status,
        navUrl: MY_DESIGN_SERVICES,
        navLabel: __('My Design Services'),
        menuProps: {
            label: useTruncation(brief.title, MAX_TITLE_LENGTH),
            links: menuLinks,
        },
    };

    return {
        menu: menu,
        links: links,
    };
}
