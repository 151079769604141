import BugsnagPluginReact from '@bugsnag/plugin-react';
import TrackingJsModule from '@vp/react-tracking';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { getCurrentCulture } from '@99designs/design-services-common';
import {
    AuthenticatedApolloProvider,
    IdentityProvider,
    RouteWithAuth,
    buildAuthConfiguration,
    useIdentityContext,
} from '@99designs/design-services-common';
import { AlertProvider } from '@99designs/design-services-layouts';
import { FullPageSpinner, NotFoundErrorPage, SwanConfiguration } from '@99designs/matching';
import i18n from '../lib/i18n';
import Index from '../routes';
import { Applicants } from '../routes/applicants';
import { Matching } from '../routes/matching';
import { Overview } from '../routes/overview';

Bugsnag.start({
    apiKey: process.env.VITE_MATCHING_BUGSNAG_API_KEY ?? '',
    enabledReleaseStages: ['production'],
    releaseStage: process.env.VITE_MATCHING_BUGSNAG_RELEASE_STAGE,
    plugins: [new BugsnagPluginReact(React)],
    onError: (event) => {
        event.addMetadata('request', {
            url: event.request.url,
            headers: event.request.headers,
        });

        event.addMetadata('debug info', {
            errorLocation: typeof window === 'undefined' ? 'server' : 'client',
        });
    },
});
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();

/**
 * The below import structure is a workaround for the fact that these packages are not built as ES
 * Modules.
 *
 * At dev-time the import is correctly importing just the default export but at build time the
 * import is importing the entire module.
 *
 * We therefore have to use the default key to get the default export.
 *
 * See: https://github.com/vitejs/vite/issues/4209
 */
const TrackingJs = (TrackingJsModule as any).default ?? TrackingJsModule; // eslint-disable-line @typescript-eslint/no-explicit-any

function App() {
    const currentCulture = useMemo(() => getCurrentCulture(), []);

    void i18n.changeLanguage(currentCulture);

    const authConfig = useMemo(() => {
        return buildAuthConfiguration(
            process.env.VITE_MATCHING_HOSTNAME || '',
            {
                internalAccess: 'wU1R9YyaNcvmExIicqGonvUVQGDkJMOr',
                directAccess: '4e301fd6cd21daa1423a7db2697e42b0',
            },
            /^https?:\/\/(.+\.opportunities\.99cluster\.com)/
        );
    }, []);

    return (
        <AlertProvider>
            <ErrorBoundary>
                <SwanConfiguration>
                    <IdentityProvider locale={currentCulture} {...authConfig}>
                        <RouteWithAuth>
                            <BrowserRouter
                                basename={
                                    process.env.NODE_ENV === 'production' ? '/design/matching' : ''
                                }
                            >
                                <AuthenticatedApolloProvider
                                    locale={currentCulture}
                                    endpoint={process.env.VITE_MATCHING_GRAPHQL_ENDPOINT || ''}
                                >
                                    <TrackingJs
                                        tenant="vistaprint"
                                        culture={currentCulture}
                                        environment={
                                            process.env.VITE_MATCHING_TRACKING_ENV || 'dev'
                                        }
                                    />

                                    <AppRoutes />
                                </AuthenticatedApolloProvider>
                            </BrowserRouter>
                        </RouteWithAuth>
                    </IdentityProvider>
                </SwanConfiguration>
            </ErrorBoundary>
        </AlertProvider>
    );
}

function SignOut() {
    const identity = useIdentityContext();
    const navigate = useNavigate();

    useEffect(() => {
        identity.SignOut();
        navigate('/');
    });

    return <FullPageSpinner />;
}

function AppRoutes() {
    return (
        <Routes>
            <Route index element={<Index />} />
            <Route path=":jobId" element={<Matching />}>
                <Route path="overview" element={<Overview />}></Route>
                <Route path="applicants" element={<Applicants />}></Route>
            </Route>

            {/* This route is only available in development to aid in account switching. */}
            {process.env.NODE_ENV === 'development' && (
                <Route path="sign-out" element={<SignOut />}></Route>
            )}

            <Route path="*" element={<NotFoundErrorPage />} />
        </Routes>
    );
}

export default App;
