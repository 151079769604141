import React, { useState } from 'react';
import { LazyQueryExecFunction } from '@apollo/client';
import { Dropdown, DropdownOption } from '@vp/swan';
import { ApplicationSort, Exact } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { ApplicantsQuery, ApplicantsQueryVariables, useJob } from '../../lib';

interface SortProps {
    query: LazyQueryExecFunction<ApplicantsQuery, Exact<ApplicantsQueryVariables>>;
}

export function Sort({ query }: SortProps) {
    const job = useJob();
    const noEmbeddings = !job.flags.hasEmbeddings;
    const defaultSort: ApplicationSort = noEmbeddings
        ? 'APPLICATION_SORT_RECENCY'
        : 'APPLICATION_SORT_BEST_MATCH';
    const [selectedSort, setSort] = useState<ApplicationSort>(defaultSort);

    return (
        <Dropdown
            defaultValue={selectedSort}
            onChange={(event) => {
                const option = event.target.value as ApplicationSort;
                query({
                    variables: {
                        jobId: job.id,
                        sort: option,
                    },
                }).then(() => {
                    setSort(option);
                });
            }}
        >
            <DropdownOption value="APPLICATION_SORT_BEST_MATCH" disabled={noEmbeddings}>
                {__('Best match')}
            </DropdownOption>
            <DropdownOption value="APPLICATION_SORT_RECENCY">{__('Most recent')}</DropdownOption>
            <DropdownOption value="APPLICATION_SORT_OLDEST">{__('Oldest')}</DropdownOption>
        </Dropdown>
    );
}
