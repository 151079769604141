import { __ } from '@99designs/i18n';
import { ApplicantsFragment } from '../graphql';

export function usePitch(applicant: ApplicantsFragment): string {
    return (
        applicant.application.data.pitch ||
        applicant.expert.biography ||
        __('Description not available')
    ).trim();
}
