import React from 'react';
import { SkeletonText, SkeletonTextLine } from '@vp/swan';
import styled from 'styled-components';

const SkeletonTileLine = styled(SkeletonTextLine)`
    height: 500px !important;
`;

const SkeletonTile = styled(SkeletonText)`
    margin-bottom: 40px;
    height: 500px;
`;

export function Skeleton() {
    return (
        <SkeletonTile>
            <SkeletonTileLine />
        </SkeletonTile>
    );
}
