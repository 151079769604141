import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Column,
    FlexBox,
    GridContainer,
    Icon,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogNav,
    Row,
    Visible,
    useScreenClass,
} from '@vp/swan';
import { useAlertContext } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { sendPopUpView } from '@99designs/tracking';
import {
    ApplicantsFragment,
    ApplicationFragment,
    ExpertDesignFragment,
    ExpertFragment,
} from '../../../lib';
import ApplicantDesignCarousel from './ApplicantDesignCarousel';
import ApplicantDetails from './ApplicantDetails';
import ApplicantSummary, { ExpertCTAs } from './ApplicantSummary';

interface ApplicantInspectorProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;

    jobId: string;
    applicants: ApplicantsFragment[];

    inspectedApplicantIndex: number;
    setInspectedExpertIndex: (index: number) => void;
}

export default function ApplicantInspector(props: ApplicantInspectorProps) {
    const {
        isOpen,
        setIsOpen,
        applicants,
        inspectedApplicantIndex,
        setInspectedExpertIndex,
        jobId,
    } = props;
    const screenClass = useScreenClass();
    const expert: ExpertFragment = applicants[inspectedApplicantIndex]?.expert;
    const designs: ExpertDesignFragment[] = applicants[inspectedApplicantIndex]?.featuredDesigns;
    const application: ApplicationFragment = applicants[inspectedApplicantIndex]?.application;
    const { messages } = useAlertContext();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    useEffect(() => {
        // This is identical to the 99d Designer Application Viewed event.

        if (isOpen) {
            sendPopUpView({
                pageName: 'Design Services:Matching:Applicant',
                pageStage: 'Design',
                pageSection: 'Design',
                label: 'Matching Applicant Pop Out',
            });
        }
    }, [isOpen]);

    return (
        <ModalDialog
            takeOver
            isOpen={isOpen}
            onRequestDismiss={() => setIsOpen(false)}
            variant={screenClass === 'xs' || screenClass === 'sm' ? 'menu' : 'standard'}
        >
            <ModalDialogContent>
                <ModalDialogNav pt={6} pb={3}>
                    <FlexBox ml={{ sm: 6 }}>
                        <Button
                            onClick={() => {
                                if (inspectedApplicantIndex !== 0) {
                                    setInspectedExpertIndex(inspectedApplicantIndex - 1);
                                }
                            }}
                            skin="secondary"
                            buttonShape="round"
                            compactMode
                            mr={3}
                            disabled={inspectedApplicantIndex === 0}
                        >
                            <Icon iconType="chevronLeft" />
                        </Button>
                        <Button
                            onClick={() => {
                                if (inspectedApplicantIndex !== applicants.length - 1) {
                                    setInspectedExpertIndex(inspectedApplicantIndex + 1);
                                }
                            }}
                            skin="secondary"
                            buttonShape="round"
                            compactMode
                            disabled={inspectedApplicantIndex === applicants.length - 1}
                        >
                            <Icon iconType="chevronRight" />
                        </Button>
                    </FlexBox>
                    <ModalDialogCloseButton accessibleText={__('Close')} />
                </ModalDialogNav>
                <ModalDialogBody>
                    <GridContainer>
                        {messages && messages.length > 0 && (
                            <Row mb={3}>
                                <Column span={12}>{messages}</Column>
                            </Row>
                        )}
                        <Row>
                            <Column span={8} spanXl={9} spanMd={6} spanSm={12}>
                                <ApplicantDesignCarousel designs={designs} expert={expert} />
                            </Column>
                            <Column span={4} spanXl={3} spanMd={6} spanSm={12}>
                                <Box m={3}>
                                    <Box>
                                        <ApplicantSummary
                                            expert={expert}
                                            jobId={jobId}
                                            pitch={application.data.pitch}
                                            isConfirmationModalOpen={isConfirmationModalOpen}
                                            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                                        />
                                        <ApplicantDetails expert={expert} />
                                    </Box>
                                </Box>
                            </Column>
                        </Row>
                    </GridContainer>
                </ModalDialogBody>
                {/* Please don't remove this padding 0 hack to make the pinned footer working with Visible component */}
                <ModalDialogFooter pinned padding={{ md: 0 }}>
                    <Visible xs sm>
                        <ExpertCTAs
                            jobId={jobId}
                            expert={expert}
                            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                        />
                    </Visible>
                </ModalDialogFooter>
            </ModalDialogContent>
        </ModalDialog>
    );
}
